

@font-face {
    font-family: 'Univers LT CYR';
    font-style: normal;
    font-weight: 100;
    src: local('UniversLTCYR-45Light'), url('./fonts/UniversLTCYR-45Light.woff') format('woff');
}
@font-face {
    font-family: 'Univers LT CYR';
    font-style: normal;
    font-weight: normal;
    src: local('UniversLTCYR-55Roman'), url('./fonts/UniversLTCYR-55Roman.woff') format('woff');
}
@font-face {
    font-family: 'Univers LT CYR';
    font-style: normal;
    font-weight: 500;
    src: local('UniversLTCYR-57Condensed'), url('./fonts/UniversLTCYR-57Condensed.woff') format('woff');
}
@font-face {
    font-family: 'Univers LT CYR';
    font-style: normal;
    font-weight: bold;
    src: local('UniversLTCYR-65Bold'), url('./fonts/UniversLTCYR-65Bold.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Univers LT CYR';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
